import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"

import "./404.scss"

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout metaTitle="Page Not Found">
        <section id="error_container" className={"pt-4 pb-4"}>
          <div id="error_detail">
            <div class="content">
              <h5 className={"mt-5 mb-5"}>Page Not Found</h5>
              <p></p>
              <Link to="/">Return to Homepage</Link>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
